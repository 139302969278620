<template>
  <validation-observer
    #default="{handleSubmit}"
  >
    <b-alert
      :show="$route.query.stripe === 'ok'"
      variant="success"
    >
      <div class="alert-body">
        <span><strong>Success!</strong> Your entity is linked with stripe successfully</span>
      </div>
    </b-alert>
    <b-card
      header="Payment"
    >
      <spinner-loader :loading="loading" />
      <b-form
        v-if="!loading"
        @submit.prevent
      >
        <!------------- Payment Type --------------->
        <b-row
          align-h="end"
          class="mb-2"
        >
          <b-col md="2">
            <b-button
              v-if="$can('AddStripeAccountToEntity','EntitiesController')"
              variant="primary"
              class="btn-block mb-1 mb-md-0"
              @click="linkWithStripeProcess"
            >
              Link With stripe
            </b-button>
          </b-col>

          <b-col md="2">
            <b-button
              v-if="$can('AddStripeAccountToEntity','EntitiesController')"
              variant="primary"
              class="btn-block"
              @click="checkStripe"
            >
              Check Link
            </b-button>
          </b-col>
        </b-row>

        <!-- Payment Type -->
        <b-form-row>
          <b-col
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Payment Type"
              rules="required"
            >
              <b-form-group
                label="Payment Type"
                label-for="payment-type"
              >
                <b-form-select
                  id="payment-type"
                  v-model="paymentDetails.payment_type"
                  :options="paymentType"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Business Type"
            >
              <b-form-group
                label="Business Type"
                label-for="business-type"
              >
                <b-form-select
                  id="business-type"
                  v-model="paymentDetails.business_type"
                  :options="businessType"
                  value-field="value"
                  text-field="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Business Name -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Business Name"
            >
              <b-form-group
                label="Business Name"
                label-for="business-name"
              >
                <b-form-input
                  id="business-name"
                  v-model="paymentDetails.business_name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Business Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <!-- Legal Name -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Legal Name"
            >
              <b-form-group
                label="Legal Name"
                label-for="legal-name"
              >
                <b-form-input
                  id="legal-name"
                  v-model="paymentDetails.legal_name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Legal Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Transit Number -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Transit Number"
            >
              <b-form-group
                label="Transit Number (Branch number)"
                label-for="transit-number"
              >
                <b-form-input
                  id="transit-number"
                  v-model="paymentDetails.transit_number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Transit Number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Institution Number -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Institution Number"
            >
              <b-form-group
                label="Institution Number (Bank number)"
                label-for="institution-number"
              >
                <b-form-input
                  id="institution-number"
                  v-model="paymentDetails.institution_number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Institution Number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <!-- Account Number -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Account Number"
            >
              <b-form-group
                label="Account Number"
                label-for="account-number"
              >
                <b-form-input
                  id="account-number"
                  v-model="paymentDetails.account_number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Account Number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Charitable Number -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Charitable Number"
            >
              <b-form-group
                label="Charitable Number"
                label-for="charitable-number"
              >
                <b-form-input
                  id="charitable-number"
                  v-model="paymentDetails.charitable_number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Charitable Number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Incorporation number -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              rules="phone"
              name="Incorporation number"
            >
              <b-form-group
                label="Incorporation number"
                label-for="incorporation-number"
              >
                <b-form-input
                  id="incorporation-number"
                  v-model="paymentDetails.phoneNum"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Incorporation number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <h6 class="my-1">
          Owners
        </h6>

        <b-form-row>
          <!-- Account Holder Name -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Account Holder Name"
            >
              <b-form-group
                label="Account Holder Name"
                label-for="account-holder-name"
              >
                <b-form-input
                  id="account-holder-name"
                  v-model="paymentDetails.account_holder_name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Account Holder Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Account Holder Position -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Account Holder Position"
            >
              <b-form-group
                label="Account Holder Position"
                label-for="account-holder-position"
              >
                <b-form-input
                  id="account-holder-position"
                  v-model="paymentDetails.account_holder_position"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Account Holder Position"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Account Holder Date Of Birth -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Account Holder Date Of Birth"
              :detect-input="false"
            >
              <b-form-group
                label="Account Holder Date Of Birth"
                label-for="account-holder-dofb"
              >
                <flat-pickr
                  id="account-holder-dofb"
                  v-model="paymentDetails.account_holder_dofb"
                  class="form-control mb-1"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d', maxDate: 'today' }"
                  @input="validationContext.validate(paymentDetails.account_holder_dofb)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <b-form-row>
              <b-col cols="12">
                <upload-image
                  id="payment-signature"
                  label="Signature"
                  :images.sync="image"
                />
              </b-col>
              <b-col cols="12">
                <images-base-64-preview
                  file-id="payment-signature"
                  :images.sync="image"
                />
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

        <submit-button
          :submit="editPaymentDetails"
          :handle-submit="handleSubmit"
        />

        <back />
        <upload-progress :progress="progress" />
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import Back from '@/common/components/common/FormInputs/Back.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import UploadImage from '@/common/components/common/FormInputs/UploadImage.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import ImagesBase64Preview from '@/common/components/common/FormInputs/ImagesBase64Preview.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'EditPaymentDetails',
  components: {
    flatPickr,
    Back,
    SubmitButton,
    UploadImage,
    UploadProgress,
    ImagesBase64Preview,
    SpinnerLoader,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      paymentType: [
        { value: 'credit', text: 'Credit Card' },
        { value: 'bank', text: 'Bank Account' },
        { value: 'tax_receipt', text: 'Tax Receipt' },
      ],
      businessType: [
        { value: 'ltd_corperate', text: 'LTD Cotporate' },
        { value: 'nonprofit', text: 'Non Profit' },
        { value: 'partnership', text: 'Partnership' },
        { value: 'proprietorship', text: 'Proprietorship' },
      ],
      paymentDetails: {},
      image: '',
      loading: true,
    }
  },
  computed: {
    entityId() {
      return this.$store.getters['mainEntity/getEntityId']
    },
  },
  setup() {
    const { calculateUploadPercentage, progress } = uploadPercentage()
    const {
      successfulOperationAlert, confirmOperation, errorAlert,
    } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()

    return {
      successfulOperationAlert,
      getValidationState,
      confirmOperation,
      calculateUploadPercentage,
      progress,
      errorAlert,
    }
  },
  created() {
    this.getPaymentDetails()
  },
  methods: {
    getPaymentDetails() {
      this.$entities.get(`internalops/entity/${this.entityId}`).then(res => {
        this.paymentDetails = res.data.data.paymentdetails ?? {}
        this.image = [res.data.data.sign_path]
      }).finally(() => { this.loading = false })
    },
    editPaymentDetails() {
      return this.$entities.put('/internalops/entity-payment-details/',
        {
          ...this.paymentDetails,
          id: this.entityId,
          sign_path: this.image[0],
        }, {
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        }).then(() => {
        this.successfulOperationAlert('Payment details is updated successfully')
      })
    },
    linkWithStripeProcess() {
      this.isStripeLinked().then(() => {
        this.confirmOperation('Entity is linked with stripe if you want to relink the entity').then(() => {
          this.linkWithStripe()
        })
      }).catch(() => {
        this.linkWithStripe()
      })
    },
    checkStripe() {
      this.isStripeLinked().then(res => {
        if (res.data.success) this.successfulOperationAlert('Stripe is already linked')
        else this.errorAlert('Stripe isn\'t linked')
      }).catch(() => {
        this.errorAlert('Stripe isn\'t linked')
      })
    },
    isStripeLinked() {
      return new Promise((resolve, reject) => this.$entities.post('/internalops/stripe/check-link', {
        entity_id: this.entityId,
      }).then(res => {
        if (res.data.success) { resolve(res) } else { reject(res) }
      }).catch(err => {
        reject(err)
      }))
    },
    linkWithStripe() {
      this.$entities.post('/internalops/AddStripeAccountToEntity', {
        entity_id: this.entityId,
      }).then(res => {
        window.open(res.data.data.url, '_blank')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
